import React from 'react';

const CapitalizeWords = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryText = queryParams.get('q');

  const capitalizeWords = (text) => {
    if (!text) return '';
    return text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <h1>{queryText ? capitalizeWords(queryText) : 'Качественный ремонт любой домашней техники в Харькове'}</h1>
  );
};

export default CapitalizeWords;