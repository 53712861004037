import React from "react";
import ReactGA from 'react-ga';
import { useSearchParams } from "react-router-dom";
import './Home.scss';
import Logo from '../../assets/img/tekhnik-service.webp';
import Sparkles from '../../assets/img/sparkles.svg';
import Money from '../../assets/img/money-money-money.svg';
import Clock from '../../assets/img/oh-my-god-clock-has-money.svg';
import Car from '../../assets/img/car.svg';
import Ukraine from '../../assets/img/Ukraine.svg';
import Heart from '../../assets/img/love.svg';
import CapitalizeWords from './CapitalizeWords';

const TRACKING_ID = "G-1PPEM4JHWB";

ReactGA.initialize(TRACKING_ID);
export default function Home(props) {
    return <>
        <section className="main">
            <header>
                
                <div className="inner-header">
                    <div className="logo">
                        <img src={Logo} alt="Лого" width={"100%"} height={"100%"}/>
                    </div>
                    <div className="time-is-money-of-course">
                        <p>Принимаем звонки с <b>8:00</b> до <b>20:00</b></p>
                        <p id="no-war">
                            {/* <b>Работаем в военное время</b> */}
                            <img src={Heart} width="16" height="16" alt="Heart symbol"/>
                            <img src={Ukraine} width="16" height="16" alt="Ukraine flag"/>
                        </p>
                    </div>
                    <div className="contacts">
                        <p>(095) 351-69-55</p>
                        <p>(068) 433-40-73</p>
                    </div>
                </div>
                <div className="menu">
                    <div className="inner-menu">
                        <a href="#what"><div className="button">Что ремонтируем</div></a>
                        <a href="#why"><div className="button">Почему мы</div></a>
                        <a href="#contacts"><div className="button">Контакты</div></a>
                    </div>
                </div>
            </header>
            <div className="image"></div>
            <div className="over-image">
                <CapitalizeWords />
            </div>
            
            <div className="info">
                <div className="content">
                    <div className="contacts-2">
                        <p>(095) 351-69-55</p>
                        <p>(068) 433-40-73</p>
                    </div>
                    <div className="content-section primary radius-top">
                        <h1 id="what">Что мы ремонтируем</h1>
                        <p>
                            Мы ремонтируем разнообразную домашнюю бытовую технику, а также некоторые виды цифровой техники:
                        </p>
                        <ul>
                            <li>Телевизоры</li>
                            {/* <li>Мониторы</li> */}
                            <li>Холодильники</li>
                            <li>Стиральные машины</li>
                            <li>Микроволновые печи</li>
                            <li>Котлы</li>
                            <li>Кофемашины</li>
                            <li>И многое другое ...</li>
                        </ul>
                        <p>
                        Если у Вас присутствуют вопросы по-поводу ремонта Вашей техники, обращайтесь к нам.
                        </p>
                    </div>
                    <div className="content-section secondary">
                        <h1 id="why">Почему мы</h1>
                        <div className="features">
                            <div className="feature-block">
                                <img src={Sparkles} alt="Качественный ремонт"/>
                                <div className="description">
                                    <h2>Качество</h2>
                                    <p>У нас Вы получаете качественный ремонт Вашей техники.</p>
                                </div>
                            </div>
                            <div className="feature-block">
                                <img src={Money} alt="Низкие цены"/>
                                <div className="description">
                                    <h2>Невысокие цены</h2>
                                    <p>Мы не завышаем стоимость ремонта.</p>
                                </div>
                            </div>
                            <div className="feature-block">
                                <img src={Car} alt="Ремонт по выезду"/>
                                <div className="description">
                                    <h2>Ремонт по выезду</h2>
                                    <p>Позвоните нам, опишите проблему, и вскоре мы будем у Вас.</p>
                                </div>
                            </div>
                            <div className="feature-block">
                                <img src={Clock} alt="Быстрота"/>
                                <div className="description">
                                    <h2>Быстрота ремонта</h2>
                                    <p>Если неисправность возможно исправить быстро, то ремонт может быть произведен прямо у Вас дома.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section primary radius-bottom">
                        <h1 id="contacts">Как с нами связаться</h1>
                        <p>Для связи с нами, просто позвоните на один из представленных ниже номеров!</p>
                        <ul>
                            <li><a href="tel:+380953516955">(095) 351-69-55</a></li>
                            <li><a href="tel:+380684334073">(068) 433-40-73</a></li>
                        </ul>
                        <p>Мы принимаем звонки с <b>8:00</b> до <b>20:00</b> и <u>запчастями не торгуем! Ремонт ТОЛЬКО крупной бытовой техники.</u></p>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div className="copy">
                <p>&copy; 2010-2024 Техник Сервис</p>
            </div>
        </footer>
    </>;
}
