import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './pages/Home/Home';

export default function App() {
    return <>
    <BrowserRouter>
        <Switch>
            <Route exact path='/' render={() => <Home/>}/>
            <Route path='*' render={() => (
                <h1>
                    О, привет. Да Вы заблудились!
                    <br/>
                    <a href="/">Милости просим на главную страницу нашего сайта!</a>
                </h1>
            )}/>
        </Switch>
    </BrowserRouter>
    </>;
}
